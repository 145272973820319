import { ADD_CART, REMOVE_CART, ITEM_REMOVE } from "./actionTypes";

const cartItemsReducer = (state = [], action) => {
    switch (action.type) {
        case ADD_CART:
            // check if the product is already in the cart
            const addIndex = state.findIndex((item) => item.id === action.payload.id);

            if (addIndex !== -1) {
                state[addIndex].count++;
                return [...state];
            } else {
                return [...state, { count: 1, ...action.payload }];
            }

        case REMOVE_CART:
            // check if the product is already in the cart
            const remIndex = state.findIndex((item) => item.id === action.payload.id);

            if (remIndex !== -1) {
                if (state[remIndex].count > 1) {
                    state[remIndex].count--;
                    return [...state];
                } else {
                    return state.filter((item) => item.id !== action.payload.id);
                }
            } else {
                return state;
            }

        case ITEM_REMOVE:
            return state.filter((item) => item.id !== action.payload.id);

        default:
            return state;
    }
};

export default cartItemsReducer;