import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-hot-toast";

import { addCart } from "../redux/cartItems/actions";

const Product = ({ product }) => {
  const dispatch = useDispatch();
  const cartItems = useSelector((state) => state.cartItems);

  const [liveQuantity, setLiveQuantity] = useState(product.quantity);
  const [disableAdd, setDisableAdd] = useState(false);

  const handleAddToCart = () => {
    dispatch(addCart(product));
    toast.success("Product added to cart");
    setLiveQuantity(liveQuantity - 1);
  };

  useEffect(() => {
    // set disableAdd to true if the product quantity is equal to the cartItems count
    const cartItem = cartItems.find((item) => item.id === product.id);
    if (cartItem && cartItem.count >= product.quantity) {
      setDisableAdd(true);
    } else {
      setDisableAdd(false);
    }
  }, [cartItems, product.id, product.quantity]);

  return (
    <div className="lws-productCard">
      <img
        className="lws-productImage"
        src={product.url}
        alt={`Look of ${product.productName}`}
      />
      <div className="p-4 space-y-2">
        <h4 className="lws-productName">{product.productName}</h4>
        <p className="lws-productCategory">{product.category}</p>
        <div className="flex items-center justify-between pb-2">
          <p className="productPrice">
            BDT <span className="lws-price">{product.price}</span>
          </p>
          <p className="productQuantity">
            QTY <span className="lws-quantity">{liveQuantity}</span>
          </p>
        </div>
        <button
          className="lws-btnAddToCart"
          disabled={disableAdd}
          onClick={handleAddToCart}
        >
          {disableAdd ? "Out of stock" : "Add to cart"}
        </button>
      </div>
    </div>
  );
};

export default Product;
