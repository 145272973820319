import { combineReducers } from "redux";

import productReducer from "./products/productReducer";
import lastIDReducer from "./lastID/lastIDReducer";
import cartItemsReducer from "./cartItems/cartItemReducer";

const rootReducer = combineReducers({
    products: productReducer,
    lastID: lastIDReducer,
    cartItems: cartItemsReducer,
});

export default rootReducer;