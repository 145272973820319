import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";

import { addCart, removeCart, itemRemove } from "../redux/cartItems/actions";

const CartProduct = ({ cartItem }) => {
  const dispatch = useDispatch();

  const [count, setCount] = useState(cartItem.count);
  const [disableAdd, setDisableAdd] = useState(false);

  const handlePlusBtn = () => {
    const newCartItem = { ...cartItem };
    delete newCartItem.count;
    dispatch(addCart(newCartItem));
    setCount(count + 1);
  };

  const handleMinusBtn = () => {
    const newCartItem = { ...cartItem };
    delete newCartItem.count;
    dispatch(removeCart(newCartItem));
    setCount(count - 1);
  };

  const handleRemoveBtn = () => {
    const newCartItem = { ...cartItem };
    delete newCartItem.count;
    dispatch(itemRemove(newCartItem));
  };

  useEffect(() => {
    if (count >= cartItem.quantity) {
      setDisableAdd(true);
    } else {
      setDisableAdd(false);
    }
  }, [cartItem.quantity, count]);

  return (
    <div className="space-y-6">
      <div className="cartCard">
        <div className="flex items-center col-span-6 space-x-6">
          <img
            className="lws-cartImage"
            src={cartItem.url}
            alt={`Look of ${cartItem.productName}`}
          />

          <div className="space-y-2">
            <h4 className="lws-cartName">{cartItem.productName}</h4>
            <p className="lws-cartCategory">{cartItem.category}</p>
            <p>
              BDT <span className="lws-cartPrice">{cartItem.price}</span>
            </p>
          </div>
        </div>
        <div className="flex items-center justify-center col-span-4 mt-4 space-x-8 md:mt-0">
          <div className="flex items-center space-x-4">
            <button
              className="lws-incrementQuantity"
              disabled={disableAdd}
              onClick={handlePlusBtn}
            >
              <i
                className="text-lg fa-solid fa-plus"
                style={{ color: disableAdd ? "gray" : "black" }}
              ></i>
            </button>
            <span className="lws-cartQuantity">{cartItem.count}</span>
            <button className="lws-decrementQuantity" onClick={handleMinusBtn}>
              <i className="text-lg fa-solid fa-minus"></i>
            </button>
          </div>

          <p className="text-lg font-bold">
            BDT{" "}
            <span className="lws-calculatedPrice">
              {cartItem.price * cartItem.count}
            </span>
          </p>
        </div>

        <div className="flex items-center justify-center col-span-2 mt-4 md:justify-end md:mt-0">
          <button className="lws-removeFromCart" onClick={handleRemoveBtn}>
            <i className="text-lg text-red-400 fa-solid fa-trash"></i>
          </button>
        </div>
      </div>
    </div>
  );
};

export default CartProduct;
