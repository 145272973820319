import { ADD_PRODUCT } from "./actionTypes";
const tempData = [
    // {
    //     id: 1,
    //     productName: "Spring and summershoes",
    //     category: "Mens shoes",
    //     url: "https://i.dummyjson.com/data/products/59/thumbnail.jpg",
    //     price: 400,
    //     quantity: 10,
    // },
    // {
    //     id: 2,
    //     productName: "Women Winter Clothes",
    //     category: "Tops",
    //     url: "https://i.dummyjson.com/data/products/40/thumbnail.jpg",
    //     price: 100,
    //     quantity: 5,
    // },
];

const productReducer = (state = tempData, action) => {
    switch (action.type) {
        case ADD_PRODUCT:
            return [...state, action.payload];

        // case REMOVE_BOOKING:
        //     return state.filter(data => data.id !== action.payload).map(obj => ({ ...obj }));

        default:
            return state;
    }
};

export default productReducer;