import { applyMiddleware, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";

import rootReducer from "./rootReducer";
import quantityCheck from "./middlewares/quantityCheck";

const store = createStore(
    rootReducer,
    composeWithDevTools(applyMiddleware(quantityCheck))
);

export default store;