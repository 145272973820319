import { UPDATE_LASTID } from "./actionTypes";


const lastIDReducer = (state = 0, action) => {
    switch (action.type) {
        case UPDATE_LASTID:
            return state + 1;

        default:
            return state;
    }
};

export default lastIDReducer;