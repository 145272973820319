import React from "react";
import { Link } from "react-router-dom";

const The404 = () => {
  return (
    <div>
      <h3>This is a 404 page</h3>
      <Link to="/">
        <button>Home</button>
      </Link>
    </div>
  );
};

export default The404;
