import React, { useState } from "react";
import { toast } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";

import { updateLastID } from "../redux/lastID/actions";
import { addProduct } from "../redux/products/actions";

const ProductAddForm = () => {
  const dispatch = useDispatch();
  const lastID = useSelector((state) => state.lastID);

  const [productName, setProductName] = useState("");
  const [category, setCategory] = useState("");
  const [imageURL, setImageURL] = useState("");
  const [price, setPrice] = useState(1);
  const [quantity, setQuantity] = useState(1);
  const [formLoading, setFormLoading] = useState(false);

  const handleProductAdd = (e) => {
    e.preventDefault();
    setFormLoading(true);
    const toastID = toast.loading("Adding Product...");
    const productID = lastID + 1;
    const productObj = {
      id: productID,
      productName: productName,
      category: category,
      url: imageURL,
      price: price,
      quantity: quantity,
    };

    // console.log(productObj);

    setTimeout(() => {
      dispatch(addProduct(productObj));
      dispatch(updateLastID());
      toast.success(`${productName} added succesfully`, {
        id: toastID,
      });
      setProductName("");
      setCategory("");
      setImageURL("");
      setPrice(1);
      setQuantity(1);
      setFormLoading(false);
    }, 2000);
  };

  return (
    <div>
      <div className="formContainer">
        <h4 className="formTitle">Add New Product</h4>
        <form
          className="space-y-4 text-[#534F4F]"
          id="lws-addProductForm"
          onSubmit={handleProductAdd}
        >
          <div className="space-y-2">
            <label htmlFor="lws-inputName">Product Name</label>
            <input
              className="addProductInput"
              id="lws-inputName"
              type="text"
              value={productName}
              onChange={(e) => setProductName(e.target.value)}
              required
            />
          </div>

          <div className="space-y-2">
            <label htmlFor="lws-inputCategory">Category</label>
            <input
              className="addProductInput"
              id="lws-inputCategory"
              type="text"
              value={category}
              onChange={(e) => setCategory(e.target.value)}
              required
            />
          </div>
          <div className="space-y-2">
            <label htmlFor="lws-inputImage">Image Url</label>
            <input
              className="addProductInput"
              id="lws-inputImage"
              type="url"
              value={imageURL}
              onChange={(e) => setImageURL(e.target.value)}
              required
            />
          </div>
          <div className="grid grid-cols-2 gap-8 pb-4">
            <div className="space-y-2">
              <label htmlFor="ws-inputPrice">Price</label>
              <input
                className="addProductInput"
                type="number"
                id="lws-inputPrice"
                value={price}
                min="1"
                max="99999"
                onChange={(e) => setPrice(e.target.value)}
                required
              />
            </div>
            <div className="space-y-2">
              <label htmlFor="lws-inputQuantity">Quantity</label>
              <input
                className="addProductInput"
                type="number"
                id="lws-inputQuantity"
                value={quantity}
                min="1"
                max="99999"
                onChange={(e) => setQuantity(e.target.value)}
                required
              />
            </div>
          </div>
          <button
            type="submit"
            disabled={formLoading}
            id="lws-inputSubmit"
            className="submit"
          >
            {formLoading ? "Adding Product..." : "Add New Product"}
          </button>
        </form>
      </div>
    </div>
  );
};

export default ProductAddForm;
