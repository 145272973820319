import { ADD_CART } from "../cartItems/actionTypes";

const quantityCheck = store => next => action => {
    const currentCartItems = store.getState().cartItems;
    // console.log(currentCartItems.length);
    // console.log(action.type);
    // console.log(action.payload);

    // get the index of the product in the cart
    const index = currentCartItems.findIndex((item) => item.id === action.payload.id);

    if (index !== -1) {
        if (action.type === ADD_CART && currentCartItems[index].count >= action.payload.quantity) return;
    }

    return next(action);
};

export default quantityCheck;