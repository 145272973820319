import React from "react";
import { useSelector } from "react-redux";

import Header from "../components/Header";
import Product from "../components/Product";
import ProductAddForm from "../components/ProductAddForm";

const Home = () => {
  const products = useSelector((state) => state.products);

  return (
    <>
      <Header />
      <main className="py-16">
        <div className="productWrapper">
          <div className="productContainer" id="lws-productContainer">
            {products.map((product) => (
              <Product key={product.id} product={product} />
            ))}
          </div>
          <ProductAddForm />
        </div>
      </main>
    </>
  );
};

export default Home;
