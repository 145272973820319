import { createBrowserRouter } from "react-router-dom";
import App from "./App";
import Cart from "./pages/Cart";
import The404 from "./pages/The404";

const router = createBrowserRouter([
    {
        "path": "/",
        "element": <App />,
        "errorElement": <The404 />
    },
    {
        "path": "/cart",
        "element": <Cart />
    },
]);

export default router;