import React from "react";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";

import logoPng from "../assets/images/logo.png";

const Header = () => {
  const cartItems = useSelector((state) => state.cartItems);
  const totalCart = cartItems.reduce((total, item) => total + item.count, 0);
  return (
    <nav className="bg-[#171C2A] py-4">
      <div className="navBar">
        <a href="/">
          <img src={logoPng} alt="LWS" className="max-w-[140px]" />
        </a>

        <div className="flex gap-4">
          <NavLink to="/" end className="navHome" id="lws-home">
            Home
          </NavLink>
          <NavLink to="/cart" end className="navCart" id="lws-cart">
            <i className="text-xl fa-sharp fa-solid fa-bag-shopping"></i>
            <span id="lws-totalCart">{totalCart}</span>
          </NavLink>
        </div>
      </div>
    </nav>
  );
};

export default Header;
