import { ADD_CART, REMOVE_CART, ITEM_REMOVE } from "./actionTypes";

export const addCart = (value) => {
    return {
        type: ADD_CART,
        payload: value,
    };
};

export const removeCart = (value) => {
    return {
        type: REMOVE_CART,
        payload: value,
    };
};

export const itemRemove = (value) => {
    return {
        type: ITEM_REMOVE,
        payload: value,
    };
};